import { makeAutoObservable } from 'mobx';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import keyBy from 'lodash/keyBy';

import services from '../services';
import { extractErrorMessage } from '../utils/helpers';

class CatalogStore {
    catalog = [];

    //keyBehaviours = [];

    threatAreaFilter = 'all';
    keyBehaviourFilter = 'all';

    threatAreaServerData = [];

    availableThreatAreas = [];
    availableKeyBehaviours = [];
    availableSubjectsByThreatArea = [];

    saving = false;
    removingNotes = [];
    loadingCatalog = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async loadThreatAreaCatalog(companyId) {
        if (this.loadingCatalog) return;
        this.loadingCatalog = true;
        this.error = null;
        try {
            const taServerData = await services.ThreatAreas.list();
            this.threatAreaServerData.replace(taServerData);

            this.extractThreatAreas(taServerData);

            this.extractKeyBehaviourNames(taServerData, this.threatAreaFilter);

            this.availableSubjectsByThreatArea = this.getSubjects(
                this.threatAreaFilter,
                this.keyBehaviourFilter,
                taServerData
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
            console.log('Error loading catalog: ', e);
        } finally {
            this.loadingCatalog = false;
        }
    }

    extractThreatAreas(data) {
        this.availableThreatAreas = data.map((threatArea) => ({
            id: threatArea.id,
            threatAreaCode: threatArea.threatAreaCode,
            threatAreaName: threatArea.threatAreaName,
        }));
    }

    extractKeyBehaviourNames(data, selectedThreatArea) {
        const selectedThreatAreaData = data.find(
            (threatArea) => threatArea.threatAreaName === selectedThreatArea
        );

        if (!selectedThreatAreaData || selectedThreatArea === 'all') {
            this.availableKeyBehaviours = data.flatMap((threatArea) =>
                threatArea.keyBehaviours.map((keyBehaviour) => ({
                    id: keyBehaviour.id,
                    kbCode: keyBehaviour.kbCode,
                    kbName: keyBehaviour.kbName,
                    threatAreaName: threatArea.threatAreaName,
                }))
            );
        } else {
            this.availableKeyBehaviours =
                selectedThreatAreaData.keyBehaviours.map((keyBehaviour) => ({
                    id: keyBehaviour.id,
                    kbCode: keyBehaviour.kbCode,
                    kbName: keyBehaviour.kbName,
                    threatAreaName: selectedThreatAreaData.threatAreaName,
                }));
        }
    }

    getSubjects(threatAreaName = 'all', keyBehaviourName = 'all', data) {
        const result = [];

        data.forEach((threatArea) => {
            if (
                threatAreaName === 'all' ||
                threatArea.threatAreaName === threatAreaName
            ) {
                const threatAreaObj = {
                    threatAreaName: threatArea.threatAreaName,
                    threatAreaCode: threatArea.threatAreaCode,
                    subjects: [],
                };

                const uniqueSubjectIds = new Set(); // Set to track unique subject IDs

                threatArea.keyBehaviours.forEach((kb) => {
                    if (
                        keyBehaviourName === 'all' ||
                        kb.kbName === keyBehaviourName
                    ) {
                        kb.subject2s.forEach((subject2) => {
                            if (!uniqueSubjectIds.has(subject2.id)) {
                                // Check if subject has not been added before
                                threatAreaObj.subjects.push(subject2);
                                uniqueSubjectIds.add(subject2.id); // Add subject ID to Set
                            }
                        });
                    }
                });

                threatAreaObj.subjects = this.processSubjects(
                    threatAreaObj.subjects,
                    threatAreaObj.threatAreaName
                );

                result.push(threatAreaObj);
            }
        });

        return result;
    }

    processSubjects(subject2s, threatAreaName) {
        const subjects = [];
        subject2s.forEach((subject2) => {
            const subject = {
                subject_id: subject2.id,
                episode_number: subject2.episode_number,
                keyBehaviours: subject2.keyBehaviours,
                threatAreaName,
            };

            if (
                subject2.subject_translations &&
                subject2.subject_translations.length > 0
            ) {
                const firstTranslation = subject2.subject_translations[0];
                for (const key in firstTranslation) {
                    subject[key] = firstTranslation[key];
                }
            }

            subject.id = subject.subject_id; //Make sure we don't use the subject translation id

            subjects.push(subject);
        });

        return subjects;
    }

    setThreatAreaFilter(value) {
        this.threatAreaFilter = value;
        this.keyBehaviourFilter = 'all';

        //With new ThreatAreaFilter, we need to find available key behaviours
        this.extractKeyBehaviourNames(this.threatAreaServerData, value);

        //And then get the subjects for the new ThreatAreaFilter
        this.availableSubjectsByThreatArea = this.getSubjects(
            value,
            this.keyBehaviourFilter,
            this.threatAreaServerData
        );
    }

    setKeyBehaviourFilter(threatArea, value) {
        this.keyBehaviourFilter = value;
        this.threatAreaFilter = threatArea;

        //Only the 'sibling' KBs should be available
        this.extractKeyBehaviourNames(this.threatAreaServerData, threatArea);

        this.availableSubjectsByThreatArea = this.getSubjects(
            this.threatAreaFilter,
            this.keyBehaviourFilter,
            this.threatAreaServerData
        );
    }

    async loadCatalog(companyId) {
        if (this.loadingCatalog) return;
        this.loadingCatalog = true;
        this.error = null;
        try {
            const result = await services.Companies.categoryService(
                companyId
            ).list();
            this.catalog.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
            console.log('Error loading catalog: ', e);
        } finally {
            this.loadingCatalog = false;
        }
    }

    async saveNote(companyId, subjectId, editNoteId, content, subject) {
        this.saving = true;

        try {
            let result;
            if (editNoteId > -1) {
                await services.Companies.subjectsService(companyId)
                    .notesService(subjectId)
                    .update({ content }, editNoteId);
                if (subject) {
                    let note = subject.notes.find((x) => x.id === editNoteId);
                    if (note) note.content = content;
                }
                return true;
            } else {
                result = await services.Companies.subjectsService(companyId)
                    .notesService(subjectId)
                    .create({ content });
                if (subject) {
                    subject.notes.push(result);
                }
                return true;
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.saving = false;
        }
    }

    async deleteNote(companyId, subjectId, id, subject) {
        this.removingNotes.push(id);
        this.error = null;
        try {
            await services.Companies.subjectsService(companyId)
                .notesService(subjectId)
                .delete(id);
            if (subject) {
                subject.notes = subject.notes.filter((x) => x.id !== id);
            }
            return true;
        } catch (e) {
            console.error(e.stack);
            this.error = extractErrorMessage(e);
        } finally {
            this.removingNotes.remove(id);
        }
    }

    get allSubjectsIndex() {
        return keyBy(
            uniqBy(
                flatten(
                    this.catalog.map((c) => {
                        return c.subjects.map((s) => {
                            return Object.assign({ categoryId: c.id }, s);
                        });
                    })
                ),
                (s) => s.id
            ),
            'id'
        );
    }
}

export default CatalogStore;
