import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import CallToActionBanner from '../components/CallToActionBanner';
import Subject from './Subject';
import { useStores } from '../hooks/use-stores';
import { ThemedButton } from '@awarego/awarego-components';
import { useFetch } from 'use-http';
import PreviewModalHeader from './previewModalHeader';
import ThemedSpinner from '../components/themed/ThemedSpinner';
function SubjectPreviewContainer({
    isOpen,
    onClose,
    subjectId,
    redirectToUpgrade,
    hideSubjectsModal,
    hideFooter,
    showLanguageSelector,
    availableLanguages,
    defaultLanguage = 'en',
    subjectTitle = null,
}) {
    const { store, catalogStore, brandingStore } = useStores();
    const { currentCompany, havePartnerSubscription } = store;
    const subjectFromCatalog = catalogStore.allSubjectsIndex[subjectId];

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    const {
        loading,
        error,
        data = [],
    } = useFetch(
        `/companies/${currentCompany.company_id}/subjects/${subjectId}?lang=${selectedLanguage}`,
        {},
        [currentCompany, selectedLanguage, subjectId]
    );

    const { supportEmail } = brandingStore;

    useEffect(() => {
        selectLanguage(defaultLanguage);
    }, [subjectId]);

    const selectLanguage = (languageId) => {
        store.resetVideoTokens();
        setSelectedLanguage(languageId);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="preview-questions-modal"
        >
            <PreviewModalHeader
                titlePrefix={'Preview:'}
                title={
                    subjectTitle ||
                    (subjectFromCatalog && subjectFromCatalog.title)
                }
                showLanguageSelector={showLanguageSelector}
                onSelectLanguage={selectLanguage}
                selectedLanguage={selectedLanguage}
                availableLanguages={availableLanguages}
                onClose={onClose}
            />
            {subjectFromCatalog && subjectFromCatalog.disabled && (
                <CallToActionBanner
                    isPartnerCompany={havePartnerSubscription}
                    supportEmail={supportEmail}
                    redirectToUpgrade={() => {
                        redirectToUpgrade && redirectToUpgrade();
                        onClose();
                        hideSubjectsModal && hideSubjectsModal();
                    }}
                />
            )}
            {!loading && data ? (
                <Subject
                    subject={data}
                    showBackLink={false}
                    language={selectedLanguage}
                    companyId={currentCompany && currentCompany.company_id}
                />
            ) : (
                <ThemedSpinner />
            )}
            {!hideFooter && (
                <div className={'modalFooter'}>
                    <ThemedButton
                        mode="primary"
                        onClick={onClose}
                        text="Back to add subjects"
                    />
                </div>
            )}
        </Modal>
    );
}

export default observer(SubjectPreviewContainer);
