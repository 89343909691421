import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';
import EmployeesCheckboxTableImpl from '../../users/components/EmployeesCheckboxTableImpl';
import { CheckboxInput, FlexDiv, Heading3 } from '@awarego/awarego-components';
import SelectAll from '../../users/components/SelectAll';
import { caseAndDiacriticsInsensitiveSort } from '../../../components/table/table-helpers';

function makeColumns(selectable, selection) {
    return [
        {
            Header: 'Name',
            id: 'name',
            accessor: (x) => {
                // scorm name  is formated as "id|[name]|[email]"
                // so check if its  scorm name and parse
                if (x.name && x.name.indexOf('|') > -1) {
                    let parts = x.name.split('|');
                    if (parts.length > 1) {
                        return parts[1];
                    } else return parts[0];
                }
                return x.name || x.email;
            },
            sortType: caseAndDiacriticsInsensitiveSort,
            Cell: observer((x) => {
                return selectable ? (
                    <CheckboxInput
                        label={x.value}
                        checked={
                            selection.effectiveSelectionOnCurrentPage[
                                x.row.original.email
                            ]
                        }
                        noYPadding={true} //Removes Y padding and margin, making it fit better into table row
                        onChange={(e) => {
                            selection.toggleRow(
                                x.row.original,
                                e.target.checked
                            );
                        }}
                    />
                ) : (
                    x.value
                );
            }),
        },
        {
            Header: 'Email',
            accessor: (x) => {
                // scorm name  is formated as "id|[name]|[email]"
                // so check if its  scorm name and parse

                if (x.name && x.name.indexOf('|') > -1) {
                    let parts = x.name.split('|');
                    if (parts.length > 2) {
                        return parts[2];
                    } else return parts[0];
                }
                return x.email;
            },
        },
    ];
}

function PreviewSyncLists({ automationId, companyId, selectable }) {
    const { automationStore } = useStores();
    const pageSize = 3;

    useEffect(() => {
        automationStore.loadPreviewSyncLists(companyId, automationId);
    }, []);

    const { loadingPreviewSyncLists } = automationStore;

    const columnsAdd = useMemo(
        () =>
            makeColumns(selectable, automationStore.previewUsersToAddSelection),
        [automationStore.previewUsersToAddSelection]
    );
    const columnsRemove = useMemo(
        () =>
            makeColumns(
                selectable,
                automationStore.previewUsersToRemoveSelection
            ),
        [automationStore.previewUsersToRemoveSelection]
    );

    const onChangePagingParams = (key) => (pageIndex, pageSize) => {
        switch (key) {
            case 'add':
                automationStore.setToAddPageData(pageIndex, pageSize);
                break;
            case 'remove':
                automationStore.setToRemovePageData(pageIndex, pageSize);
                break;
        }
    };

    if (loadingPreviewSyncLists == null) return;
    if (loadingPreviewSyncLists === true) {
        return (
            <FlexDiv px={8} py={24} alignCenter justifyCenter>
                <Heading3>Loading...</Heading3>
            </FlexDiv>
        );
    }

    if (
        loadingPreviewSyncLists === false &&
        automationStore.previewUsersToAdd.length === 0 &&
        automationStore.previewUsersToRemove.length === 0
    ) {
        return (
            <FlexDiv px={8} py={24} alignCenter justifyCenter>
                <Heading3>Users Lists are up to date </Heading3>
            </FlexDiv>
        );
    }

    return (
        <div>
            <FlexDiv column gap={2}>
                <FlexDiv column>
                    <Heading3>New Users</Heading3>
                    {selectable && (
                        <SelectAll
                            selection={
                                automationStore.previewUsersToAddSelection
                            }
                            usersTotal={
                                automationStore.previewUsersToAdd.length
                            }
                            pageSize={5}
                            loadingUsersList={false}
                        />
                    )}
                    <EmployeesCheckboxTableImpl
                        store={automationStore}
                        columns={columnsAdd}
                        usersList={automationStore.previewUsersToAddCurrentPage}
                        usersTotal={automationStore.previewUsersToAdd.length}
                        onChangePagingParams={onChangePagingParams('add')}
                        hidePageSizeSelector={true}
                        pageSize={pageSize}
                        loadingUsersList={false}
                        rowsPerPage={pageSize}
                    />
                </FlexDiv>
                <FlexDiv column>
                    <Heading3>Obsolete Users</Heading3>

                    {selectable && (
                        <SelectAll
                            selection={
                                automationStore.previewUsersToRemoveSelection
                            }
                            usersTotal={
                                automationStore.previewUsersToRemove.length
                            }
                            pageSize={pageSize}
                            loadingUsersList={false}
                        />
                    )}
                    <EmployeesCheckboxTableImpl
                        store={automationStore}
                        columns={columnsRemove}
                        usersList={
                            automationStore.previewUsersToRemoveCurrentPage
                        }
                        usersTotal={automationStore.previewUsersToRemove.length}
                        onChangePagingParams={onChangePagingParams('remove')}
                        hidePageSizeSelector={true}
                        pageSize={pageSize}
                        loadingUsersList={false}
                        rowsPerPage={pageSize}
                    />
                </FlexDiv>
            </FlexDiv>
        </div>
    );
}

export default observer(PreviewSyncLists);
