import React, { useMemo, useState, useEffect, useCallback } from 'react';
import TableServer from '../../../components/table/TableServer';
import StyledLink from '../../../components/StyledLink';
import {
    palette,
    Tag,
    DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import RequireRole from '../../RequireRole';
import { useStores } from '../../../hooks/use-stores';
import { LINKS } from '../../../utils/constants';
import { createNoUsersView, createBadge } from '../users-helpers';
import debounceFn from 'debounce-fn';

function SingleListTable({
    currentCompany,
    createDisabled,
    listId,
    rowsPerPage = 10,
}) {
    const { userStore, commonStore } = useStores();
    const [manualInitialPageTrigger, setManualInitialPageTrigger] = useState(0);
    const [manualPaginationTrigger, setManualPaginationTrigger] =
        useState(null);
    const companyId = currentCompany.company_id;

    useEffect(() => {
        userStore.setQueryContext({ p: 0, n: rowsPerPage });
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                id: 'name',
                accessor: (x) => {
                    // scorm name  is formatted as "id|[name]|[email]"
                    // so check if its  scorm name and parse
                    if (x.name && x.name.indexOf('|') > -1) {
                        let parts = x.name.split('|');
                        if (parts.length > 1) {
                            return parts[1];
                        } else return parts[0];
                    }
                    return x.name;
                },
                Cell: (x) => {
                    return (
                        <>
                            <StyledLink
                                to={`/users/user/${x.row.original.email}`}
                                $hoverColor={palette.graphite.onyx}
                                $underlined={true}
                            >
                                {x.value && x.value.length > 0 ? (
                                    x.value
                                ) : (
                                    <Tag text="No Name" />
                                )}{' '}
                            </StyledLink>
                            {x.row.original.source != null &&
                                x.row.original.source != '' &&
                                createBadge(x.row.original.source)}
                        </>
                    );
                },
            },

            {
                Header: 'Email Address',
                id: 'email',
                accessor: (x) => {
                    // scorm name  is formatted as "id|[name]|[email]"
                    // so check if its  scorm name and parse
                    if (x.name && x.name.indexOf('|') > -1) {
                        let parts = x.name.split('|');
                        if (parts.length > 2) {
                            return parts[2];
                        } else return parts[0];
                    }
                    return x.email;
                },
            },

            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
        ],
        []
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <DarkArrowTooltipIcon
                        customFunction={onDeleteEmployeeFromList}
                        row={row}
                        title="Delete Employee"
                        icon={'delete'}
                    ></DarkArrowTooltipIcon>
                </RequireRole>
            </>
        );
    };

    useEffect(() => {
        if (
            (userStore.usersSearchedValue &&
                userStore.usersSearchedValue.value_ !== null) ||
            userStore.usersSearchedValue === ''
        ) {
            loadDataWithFilterCallback(userStore.usersSearchedValue);
        }
    }, [userStore.usersSearchedValue]);

    useEffect(() => {
        if (userStore.usersDeleted > 0) {
            // if there is only one user displayed on page n, once he is deleted move to page n-1
            // page n != 0
            if (
                userStore.pageIndex ==
                userStore.usersFiltered / userStore.queryContext.n
            ) {
                const newPageIndex = Math.max(userStore.pageIndex - 1, 0);
                setManualPaginationTrigger(newPageIndex);
                userStore.setPagingParams(
                    newPageIndex,
                    userStore.queryContext.n
                );
                userStore.loadUsersByListId(
                    companyId,
                    listId,
                    userStore.usersSearchedValue?.value_
                );
            }
            //if user is deleted from page 0, reload to move user from page 1 to its place
            else if (
                userStore.pageIndex == 0 &&
                !userStore.usersFiltered < userStore.queryContext.n
            ) {
                userStore.loadUsersByListId(
                    companyId,
                    listId,
                    userStore.usersSearchedValue?.value_
                );
            }
        }
    }, [userStore.usersDeleted]);

    const employeeDeletedCallback = () => {
        commonStore.success('User deleted.');
    };

    const onDeleteEmployeeFromList = (e, user) => {
        e.stopPropagation();
        let userToDelete = {
            companyId: companyId,
            listsId: listId,
            email: user.email,
        };
        let description =
            'Are you sure you want to delete this user from list?';
        userStore.buildDeleteUserDialog(
            userToDelete,
            description,
            employeeDeletedCallback
        );
    };

    const onChangePagingParams = (pageIndex, pageSize) => {
        if (
            userStore.pageIndex != pageIndex ||
            userStore.pageSize != pageSize
        ) {
            userStore.setPagingParams(pageIndex, pageSize);
        }
    };

    const onChangeSort = (sortBy) => {
        userStore.setSortBy(sortBy);
    };

    const pagesTotal = useMemo(() => {
        return userStore.calculateUsersPageCount();
    }, [userStore.pageSize, userStore.usersFiltered]);

    const loadDataWithFilterCallback = useCallback(async () => {
        return debouncedLoadFilteredUsersByListId();
    }, [userStore.usersSearchedValue]);

    const debouncedLoadFilteredUsersByListId = debounceFn(
        () => {
            // manually set page to 0 for searching, calls goToPage(0) method of SimpleTable
            setManualInitialPageTrigger(
                (manualInitialPageTrigger) => manualInitialPageTrigger + 1
            );
            void userStore.debouncedLoadUsersByListId(
                companyId,
                listId,
                userStore.usersSearchedValue
            ); // this maybe can be called directly without debounce! @todo
        },
        { wait: 500 }
    );

    return (
        <>
            <TableServer
                columns={columns}
                data={userStore.usersByList.slice()}
                hidePagination={
                    userStore.usersFiltered <= rowsPerPage ||
                    userStore.loadingUsersByList
                }
                link={LINKS.user}
                defaultSortBy={'name'}
                defaultSortDescent={false}
                rowsTotal={userStore.usersFiltered}
                pagesTotal={pagesTotal}
                onChangePagingParams={onChangePagingParams}
                onChangeSort={onChangeSort}
                loadingData={userStore.loadingUsersByList}
                initialPageSize={rowsPerPage}
                NoDataComponent={createNoUsersView(
                    currentCompany,
                    createDisabled,
                    'users'
                )}
                manualInitialPageTrigger={manualInitialPageTrigger}
                manualPaginationTrigger={manualPaginationTrigger}
            />
        </>
    );
}

export default observer(SingleListTable);
