/* eslint-disable no-undef */
import { makeAutoObservable, runInAction, reaction } from 'mobx';
import services from '../services';
import { awareGoTheme as styledComponentsThemeFile } from '../theme';
import { AwareGOTheme as muiThemeFile } from '../theme-mui';
import { backOffDelay, isArray } from '../utils/helpers';
import defaults from 'lodash/defaults';

const { REACT_APP_MY_URL: myUrl } = import.meta.env;
const pattern = /^((http|https):\/\/)/;
import { palette } from '@awarego/awarego-components';

class BrandingStore {
    brandingLoaded = false;
    branding = null;

    grayColor = palette.graphite.ash; //Used for 'irrelevant' answers
    redColor = palette.vibrant.red;
    orangeColor = palette.vibrant.orange;
    yellowColor = palette.vibrant.gold;
    lightgreenColor = palette.evergreen.lime;
    greenColor = palette.evergreen.aspargus;

    constructor(mainStore, companyStore) {
        makeAutoObservable(this);
        this.mainStore = mainStore;
        this.companyStore = companyStore;

        reaction(
            () => this.mainStore.currentCompany,
            (company) => {
                if (company) {
                    this.loadBranding(company.company_id);
                } else this.loadBranding();
            }
        );
        reaction(
            () => this.mainStore.serverData,
            (data) => {
                if (data && data.length === 0) {
                    this.loadBranding();
                }
            }
        );
    }

    setError(error, name) {
        switch (name) {
            default:
                this.error = error;
        }
    }

    async loadBranding(companyId, counter = 0) {
        if (counter === 0 && this.reloadTimeout) {
            // new request for branding until loading previous one
            try {
                clearTimeout(this.reloadTimeout);
            } catch (e) {
                console.log(e);
            }
            this.reloadTimeout = null;
        }
        this.brandingLoaded = false;
        try {
            let result = companyId
                ? await services.Companies.BrandingService(companyId).list()
                : await services.Branding.list();
            runInAction(() => {
                this.branding = result;
                this.brandingLoaded = true;
            });
        } catch (e) {
            if (counter < 10)
                this.reloadTimeout = setTimeout(async () => {
                    await this.loadBranding(companyId, counter + 1);
                }, backOffDelay(1500, counter));
        }
    }

    get logo() {
        let logoPath = '/static/img/logo_simple.png';

        if (
            this.companyStore.currentCompany &&
            this.companyStore.currentCompany.logo_name
        ) {
            logoPath =
                import.meta.env.REACT_APP_RESOURCES_URL +
                '/' +
                import.meta.env.REACT_APP_COMPANY_RESOURCES_DIR +
                '/' +
                this.companyStore.currentCompany.logo_name;
        } else if (this.branding && this.branding['ui.logo']) {
            logoPath = this.branding['ui.logo'];
        }
        return logoPath;
    }

    get favicon() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['ui.favicon']) ||
            'favicon_generic.ico'
        );
    }

    get ad_enabled() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['ui.ad_enabled']) || false;
    }

    get name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['name']) || 'AwareGO';
    }

    get account_name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['account_name']) || 'AwareGO';
    }

    get helpUrl() {
        const defaultHelpUrl = 'https://help.awarego.com/';
        if (!this.brandingLoaded) return defaultHelpUrl;
        return (
            (this.branding && this.branding['ui.help_url']) || defaultHelpUrl
        );
    }

    get resourcesUrl() {
        const defaultResourcesUrl = '/resources';
        if (!this.brandingLoaded) return defaultResourcesUrl;
        return (
            (this.branding && this.branding['ui.resources_url']) ||
            defaultResourcesUrl
        );
    }

    get termsUrl() {
        const defaultTermsUrl = 'https://www.awarego.com/terms/';
        if (!this.brandingLoaded) return defaultTermsUrl;
        return (
            (this.branding && this.branding['ui.terms_url']) || defaultTermsUrl
        );
    }

    get privacyUrl() {
        const defaultPrivacyUrl = 'https://www.awarego.com/privacy';
        if (!this.brandingLoaded) return defaultPrivacyUrl;
        return (
            (this.branding && this.branding['ui.privacy_url']) ||
            defaultPrivacyUrl
        );
    }

    get checkEmailUrl() {
        const defaultTermsUrl =
            'https://www.awarego.com/how-to-whitelist-emails/';
        if (!this.brandingLoaded) return defaultTermsUrl;
        return (
            (this.branding && this.branding['ui.check_email_url']) ||
            defaultTermsUrl
        );
    }

    get integrationADUrl() {
        const defaultIntegrationADUrl = 'https://help.awarego.com/azure-ad';
        if (!this.brandingLoaded) return defaultIntegrationADUrl;
        return (
            (this.branding && this.branding['ui.integration_ad_url']) ||
            defaultIntegrationADUrl
        );
    }

    get integrationSSOUrl() {
        const defaultIntegrationSSOUrl = 'https://help.awarego.com/azure-sso';
        if (!this.brandingLoaded) return defaultIntegrationSSOUrl;
        return (
            (this.branding && this.branding['ui.integration_sso_url']) ||
            defaultIntegrationSSOUrl
        );
    }

    get integrationGoogleUrl() {
        const defaultIntegrationGoogleUrl =
            'https://help.awarego.com/google-suite';
        if (!this.brandingLoaded) return defaultIntegrationGoogleUrl;
        return (
            (this.branding && this.branding['ui.integration_google_url']) ||
            defaultIntegrationGoogleUrl
        );
    }

    get integrationTeamsUrl() {
        const defaultIntegrationTeamsUrl =
            'https://help.awarego.com/integration/teams';
        if (!this.brandingLoaded) return defaultIntegrationTeamsUrl;
        return (
            (this.branding && this.branding['ui.integration_teams_url']) ||
            defaultIntegrationTeamsUrl
        );
    }

    get integrationSlackUrl() {
        const defaultIntegrationSlackUrl = 'https://help.awarego.com/slack';
        if (!this.brandingLoaded) return defaultIntegrationSlackUrl;
        return (
            (this.branding && this.branding['ui.integration_slack_url']) ||
            defaultIntegrationSlackUrl
        );
    }

    get APIhelpUrl() {
        const defaultAPIhelpUrl = 'https://api.awarego.com';
        if (!this.brandingLoaded) return defaultAPIhelpUrl;
        return (
            (this.branding && this.branding['ui.api_help_url']) ||
            defaultAPIhelpUrl
        );
    }

    get materialsVisible() {
        if (!this.brandingLoaded) return false;
        const value = this.branding && this.branding['ui.content.materials'];
        return value != null ? value === '1' : true;
    }

    get supportEmail() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['contact.support']) ||
            'support@awarego.com'
        );
    }

    get lmsDomain() {
        const defaultLmsDomain = 'lms.awarego.com';
        if (!this.brandingLoaded) return defaultLmsDomain;
        if (this.branding && this.branding['lms_domain']) {
            if (isArray(this.branding['lms_domain']))
                return this.branding['lms_domain'][0];
            return this.branding['lms_domain'];
        }

        return defaultLmsDomain;
    }

    get myDomain() {
        if (!this.brandingLoaded) return myUrl;
        if (this.branding && this.branding['my_domain']) {
            let url = this.branding['my_domain'];
            if (!pattern.test(url)) {
                url = 'https://' + url;
            }
            return url;
        }
        return myUrl;
    }

    get theme() {
        let perPartner = {};

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                perPartner.primary = this.branding['ui.colors.primary'];
            if (this.branding['ui.colors.secondary'])
                perPartner.secondary = this.branding['ui.colors.secondary'];
        }

        let theme = defaults({}, perPartner, styledComponentsThemeFile);
        return theme;
    }

    get muiTheme() {
        const theme = muiThemeFile;

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                theme.palette.primary = theme.palette.augmentColor({
                    color: {
                        main: this.branding['ui.colors.primary'],
                    },
                });
            if (this.branding['ui.colors.secondary'])
                theme.palette.secondary = theme.palette.augmentColor({
                    color: {
                        main: this.branding['ui.colors.secondary'],
                    },
                });
        }

        return theme;
    }

    getScoreDefinitionByIndex(index) {
        if (this.brandingLoaded && this.branding.assessmentScoreRanges) {
            return (
                this.branding.assessmentScoreRanges.company[index] ||
                this.branding.assessmentScoreRanges.global[index]
            );
        }
    }

    getScoreDefinition(percentage) {
        let roundedPercentage = Math.floor(percentage);
        if (this.brandingLoaded && this.branding.assessmentScoreRanges) {
            let def = this.branding.assessmentScoreRanges.company.find(
                (x) =>
                    roundedPercentage >= x.from_score &&
                    roundedPercentage <= x.to_score
            );
            if (!def)
                def = this.branding.assessmentScoreRanges.global.find(
                    (x) =>
                        roundedPercentage >= x.from_score &&
                        roundedPercentage <= x.to_score
                );

            if (def && !def.color) {
                switch (def.category) {
                    case 'error':
                        def.color = this.redColor;
                        break;
                    case 'warn':
                        def.color = this.yellowColor;
                        break;
                    case 'info':
                    case 'success':
                    default:
                        def.color = this.greenColor;
                }
            }
            return def;
        }
    }
}

export default BrandingStore;
